<template>
  <div>
    <filter-option-panel
      :resetOption="resetOption"
      :open="openFilterPanel"
      @emitMessage="receiveMessage"
    />
    <div class="filter-cont" >
      <div v-if="isLoading" class="d-flex loading m-auto">
        <img class="m-auto" src="/assets/img/loading1.gif" alt="Loading..." />
      </div>
      <div v-else>
        <app-deal-menu-picker
          :pickCodeCategory="pickCodeCategory"
          :pickCodeSubcategory="pickCodeSubcategory"
          @emitMessage='receiveMessage'
        />
        <div class="bg-white deal-container">
          <app-deal-batch
            v-for="(batch, index) in sortedDealsPerHotel"
            :key="index"
            :batch="batch"
            :batchIndex="index"
            :forceHideExtraDeals="index !== shownExtraDealsIndex"
            :hideHeaderForSingleDeal=true
            @emitMessage="receiveMessage"
          />
        </div>
      </div>
    </div>
    <footer-desktop />
  </div>
</template>

<script>
import TemplateDealFilter from './TemplateDealFilter';

export default {
  name: 'DealFilterDesktop',
  components: {
    FilterOptionPanel: () => import('@/components/dealFilter/FilterOptionPanel/FilterOptionPanelDesktop'),
    AppDealMenuPicker: () => import('@/components/dealFilter/atoms/AppDealMenuPicker'),
    AppDealBatch: () => import('@/components/dealFilter/atoms/AppDealBatch'),
    FooterDesktop: () => import('@/components/home/FooterDesktop'),
  },
  extends: TemplateDealFilter,
};
</script>

<style lang="less">
  @import "../../../../public/assets/less/bonaufDesktop.less";

  .bonauf-desktop {
    .filter-cont {
      .loading {
        width: 100px;
        min-height: 40vh;
      }
      .deal-container {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        min-height: 250px;
      }
    }
  }
  @media (max-width: 1200px) {
    .bonauf-desktop {
      .deal-container {
        max-width: 900px;
      }
    }
  }
  @media (min-width: 1200px) {
    .bonauf-desktop {
      .deal-container {
        max-width: 1200px;
      }
    }
  }
</style>
